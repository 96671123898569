@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-brands/css/uicons-brands.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-solid-straight/css/uicons-solid-straight.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-solid-rounded/css/uicons-solid-rounded.css');
@import url('https://cdn-uicons.flaticon.com/2.1.0/uicons-bold-rounded/css/uicons-bold-rounded.css');

h1, h2{
    color:aliceblue;
}
a, p{
    color: aliceblue;
    text-decoration: none;
}
h1{
    font-size: 22px;
    margin-left: 25px;
}
h2{
    margin-left: 25px;
}
.link-profile{
    font-size: 30px;
}
html{
    height: 100%;
	margin: 0px;
}
body{
    height: 100%;
	margin: 0px;
    box-sizing: border-box;
    top:0;
    left: 0;
    background: radial-gradient(circle, rgb(18, 30, 52) 1%, rgb(17, 17, 41) 35%, rgb(7, 6, 13) 100%);
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    width: 100vw;
    height: 100vh;
    background-attachment: fixed;
    font-weight: 10px;
    font-style: normal;
}
.text-justify{
    text-align: justify;
}

.header-bar{
    top:0;
    width: 100%;
    display: flex;
    gap: 10px;
    position: fixed;
    height: 70px;
    justify-content: space-between;
    backdrop-filter: blur(14px); 
    box-shadow: 0 1px 2px rgba(129, 124, 124, 0.015); 
    background-color: rgba(5, 8, 27, 0.541); 
}
.navigation-menu{ 
    margin-right: 20px;
    display: flex;
    gap: 30px;
    justify-content: space-between;
}
.navigation-menu li{
    padding: 10px 10px;

}

.navigation-menu li:hover{
    transform: scale(1.1);
    transition: transform .4s;
    padding: 10px 10px;
    background-color: rgba(71, 143, 231, 0.426); 
    border-radius: 5px;
}
.mainContainer{
    min-height: 100%;
    height: 5000px;
    margin-top: 90px;
    width: 100%;
    height: 100%;
    /* background-color: aquamarine; */
    display:flex;
}
.leftSide{
    width: 50%;
    height: auto;
    /*background-color: blue; */
    position: fixed;
}

.personalInfo-leftSide{
    margin: auto;
    height: auto;
    width: 90%;
    border-radius: 5px;
    backdrop-filter: blur(14px); 
    background-color: rgba(255, 255, 255, 0.061); 
    padding: 20px 0;
}
.personalInfo-container{
    margin: auto;
    width: 90%;
}

/*------*/ 
.rightSide{
    border-radius: 5px;
    width: 50%;
    background-color: rgba(80, 78, 78, 0.081);
    display: block;
    position:static;
    margin-left: 50%;
}
/*---ABOUT---*/ 
.about-card{
    width: 90%;
    margin: 20px auto;
}
/*------*/ 
.experience-container{
    margin: auto;

   
    /*overflow-y: scroll; */
}
.experience-card{
    margin: 10px auto;
    width: 90%;
    display: flex;
    gap: 20px;
    border-radius: 5px;
    background-color:rgba(200, 200, 255, 0.012);
}
.experience-icon{
    margin-left: 10px;
}
.experience-card:hover{
    backdrop-filter: blur(24px); 
    box-shadow: 0 1px 8px rgba(213, 207, 207, 0.027);
    transform: scale(1.01);
    transition: transform .2s;
}
.reduce-width{
    width: 95%;
}

.link-profile{
    display: flex;
    /* border: 1px solid black; */
    justify-content: space-evenly;
}
li{
   list-style: none; 
   text-decoration: none;
}

/* --- Project ---*/
.project-container{
    margin: auto;
    width: 90%;
    display: flex;
  
    margin: 30px auto;
}
.image-container{
    width: 25%;
  
}
img{
    margin-top: 20px;
    margin-left: 10px;
    width: 90%;
    border: 2px solid white;
    border-radius: 3px;
}
.project-info{
    padding: 0px 1px;
    margin-left: 10px;
}
.tag{
    display: flex;
    gap: 10px;
   
}
.tag-container{
    background-color: rgba(167, 229, 229, 0.36);
    border-radius: 10px;
    padding: 0 10px;
 
}
.tag p{
    color:aqua
}

@media only screen and (max-width: 600px){
    body{
        background: radial-gradient(circle, rgb(68, 72, 78) 1%, rgb(41, 41, 49) 35%, rgb(7, 6, 13) 100%);
        width: 100%;
        height: 100vh;
        background-attachment: fixed;
        font-weight: 8px;
    }
    a, p{
        color: rgb(181, 181, 181);
        text-decoration: none;
    }
    h1{
        
        font-size: 15px;
        margin:  10px;
    }
    h2{
        color:aqua;
        margin-left: 25px;
       
    }
    .center-text{
        text-align: center;
    }
    .center-content{
        margin: 0 auto;
        display: flex;
        justify-content: space-around;
        align-items: center;

    }
    .title-place{
       padding-top: 30px;
       text-align: center;
       
    }
    .link-profile{
        font-size: 20px;
    }
    .link-profile li{
        justify-items: center;
    }

    .link-profile a{
        color:aqua;
        justify-content: center;
        display: flex;
        list-style-type: none;
        width: 18%;
        height: 60px;
        line-height: 60px/* this is for put in the center of the line  */
    }
   
    .header-bar{
        display:block;
        /*position: relative; */
        top:0;
        width: 100%;
        z-index: 1;
        backdrop-filter: blur(14px); 
        position: fixed;
        height: 130px;
      
        background-color: rgba(0, 0, 0, 0.421); 
        
    }
  

    .navigation-menu {
        width: 100%;
        height: 30px;
        margin: 0px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 0;

    }
    .navigation-menu li{
        width: auto;
        padding: 0;
    }

    .loading-line {
        margin-top: 0;
        width: 100%;
        height: 2px;
        background-color: #000;
        overflow: hidden; 
      }
      
      .loading-line::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: rgba(0, 255, 255, 0.254);
        backdrop-filter: blur(14px); 
        animation: loadingAnimation 2s linear infinite;
        margin-top: 0;
        
       
      }
      
      @keyframes loadingAnimation {
        0% {
          left: -100%;
        }
        100% {
          left: 100%;
        }
      }

    .mainContainer{
        display: block;
        width: 100%;
    }
    .leftSide{
        width: 100%;
       
        position: relative;
    }
    .rightSide{
        width: 90%;
        display: block;
        position: relative;
     
        margin: 0 auto;
    }
    /* Experience Container */ 
    .experience-container{
        margin: auto;
    }
    .experience-card{
        margin: 10px auto;
        width: 90%;
        display: block;
        gap: 10px;
        border-radius: 5px;
        background-color:rgba(200, 200, 255, 0.012);
    }
    .experience-icon{
        padding: 5px;
        font-size: 30px;
        text-align: center;
    }
    .experience-card:hover{
        backdrop-filter: blur(24px); 
        box-shadow: 0 1px 8px rgba(213, 207, 207, 0.027);
        transform: scale(1.01);
        transition: transform .2s;
    }
    
}